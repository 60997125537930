.tiny-desc-container {
  min-height: 400px;
  border: 1px solid #ddd;
  padding: 8px;
}
.ap-single-content-des {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 20px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 540px) {
  .tiny-desc-container {
    min-height: 300px;
    border: 1px solid #ddd;
    padding: 8px;
  }
}
@media only screen and (min-width: 768px) {
  .ap-single-content-des {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 20px;
    margin-bottom: 20px;
  }
}
