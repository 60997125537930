.add-prod-main-screen-btn {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  background-color: #fff;
  border: none;
  gap: 10px;
  padding: 0 10px;
  border-radius: 3px;
  transition: 0.3s;
}
.add-prod-main-screen-btn svg {
  color: #4782da;
}
.add-prod-main-screen-btn p {
  font-size: 20px;
  font-weight: 500;
  color: #4782da;
}

button.add-prod-main-screen-btn:hover {
  background-color: #4782da;
}

button.add-prod-main-screen-btn:hover svg,
button.add-prod-main-screen-btn:hover p {
  color: #fff;
}

.apmsb-1 {
  animation: apmsb-animation-1 0.5s ease forwards;
}
.apmsb-2 {
  animation: apmsb-animation-2 0.5s ease forwards;
}

@keyframes apmsb-animation-1 {
  0% {
    transform: translateX(-50vw);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes apmsb-animation-2 {
  0% {
    transform: translateX(50vw);
  }
  100% {
    transform: translateX(0);
  }
}
