.prw-customer-address-container {
  display: grid;
  grid-template-columns: auto 44px;
  grid-gap: 15px;
  padding: 20px;
  background-color: #fff;
  margin-bottom: 20px;
  align-items: center;
}
button.prw-customer-address-btn {
  height: 38px;
  width: 38px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  border-radius: 3px;
}
button.prw-customer-address-btn svg {
  color: #444;
}

.prw-products-table {
  background-color: white;
  padding: 20px;
  border-bottom: 1px solid #ddd;
  height: calc(100vh - 372px);
  overflow-y: auto;
}
.prw-products-table-header {
  display: grid;
  grid-template-columns: 220px 70px 1fr 1fr 75px;
  border: 1px solid #ddd;
}
.prw-products-table-header > div {
  border-right: 1px solid #ddd;
  padding: 10px;
  font-weight: 500;
  font-size: 13px;
}
.prw-products-table-header > div:last-child {
  border: none;
}
.prw-products-table-single-row {
  display: grid;
  grid-template-columns: 220px 70px 1fr 1fr 75px;
  border: 1px solid #ddd;
  border-top: none;
}
.prw-products-table-single-row > div {
  border-right: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}
.prw-product-table-img-name {
  display: grid !important;
  grid-template-columns: 75px auto !important;
  justify-content: left !important;
}
.prw-product-table-img {
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}
.prw-products-table-single-row > div:last-child {
  border-right: none;
}

.prw-product-table-img img {
  max-width: 100%;
  max-height: 100%;
}
.prw-table-qty {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.prw-table-qty input {
  max-width: 100%;
  border: 1px solid #ddd;
  padding: 10px 5px;
  text-align: center;
}
.prw-overview-table {
  background-color: #fff;
  padding: 20px;
}
.prw-overview-head {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border: 1px solid #ddd;
}
.prw-overview-head > div {
  font-size: 13px;
  font-weight: 600;
}
.prw-overview-head > div {
  font-size: 13px;
  font-weight: 600;
  border-right: 1px solid #ddd;
  padding: 8px 5px;
  text-align: center;
}
.prw-overview-head > div:last-child {
  border: none;
}
.prw-overveiw-body {
  border: 1px solid #ddd;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border-top: 0;
}
.prw-overveiw-body > div {
  font-size: 13px;
  font-weight: 400;
  padding: 8px 5px;
  text-align: center;
}
.prw-action-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.prw-action-btns-left button {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 13px;
  border: 1px solid #222;
  background-color: white;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 3px;
}
.prw-action-btns-left button:hover {
  background-color: #222;
  color: #ddd;
}
.prw-action-btns-left {
  display: flex;
  align-items: center;
  gap: 15px;
}
button.prw-pay-btn {
  font-size: 13px;
  padding: 10px 30px;
  background-color: #4782da;
  border: 1px solid #4782da;
  color: white;
  cursor: pointer;
  border-radius: 3px;
  transition: 0.3s;
}
button.prw-pay-btn:hover {
  background-color: #2e62b1;
}

.discount-form {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.discount-form button {
  width: 200px;
}

.customer-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  transition: all 0.3s;
}

.product-delete-button {
  cursor: pointer;
}

.close-button {
  cursor: pointer;
  font-size: 16px;
  margin-top: 16px;
}
