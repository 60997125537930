.addBtn {
  display: flex;
  justify-content: flex-end;
  margin-right: 5rem;
  margin-top: 1rem;
}
.addDigitalProduct {
  display: flex;
  justify-content: flex-end;
  margin-right: 7.2rem;
  margin-top: 1rem;
}
.add-product-widget-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
}
.add-product-single-widget {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 5px #ddd;
}
.widget-title {
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  border-bottom: 1px solid #edeef4;
}
.widget-content-container {
  padding: 15px 20px 20px;
}
.add-product-widget-container > div {
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  gap: 20px;
}

@media only screen and (min-width: 1080px) {
  .add-product-widget-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 15px;
  }
}

/* custom confi-switch styles */
.confi-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  transform: scale(0.65);
}

.confi-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.confi-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.confi-switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

/* .confi-switch input:checked + .slider {
  background-color: #6bbd45;
}

.confi-switch input:focus + .slider {
  box-shadow: 0 0 1px #6bbd45;
} */

.confi-switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.confi-switch .slider.round {
  border-radius: 34px;
}

.confi-switch .slider.round:before {
  border-radius: 50%;
}
