.pos-activation-widget {
  padding: 15px;
}
.page-title {
  text-align: center;
  font-size: 24px;
  color: #585858;
  font-weight: 500;
  margin-bottom: 20px;
}
.pos-activation-widget {
  width: 100%;
  /* max-width: 700px; */
  /* margin: 0 auto; */
  background-color: white;
  border-radius: 3px;
}
h3.confi-widget-title {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}
.confi-widget-body {
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pos-confi-conntainer {
  display: grid;
  grid-template-columns: 150px auto;
  align-items: center;
  gap: "10px";
}
/* custom confi-switch styles */
.confi-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  transform: scale(0.65);
}

.confi-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.confi-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.confi-switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.confi-switch input:checked + .slider {
  background-color: #4782da;
}

.confi-switch input:focus + .slider {
  box-shadow: 0 0 1px #4782da;
}

.confi-switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.confi-switch .slider.round {
  border-radius: 34px;
}

.confi-switch .slider.round:before {
  border-radius: 50%;
}
