.pagination {
    // padding: 30px 0 0 0;
    max-width: 800px;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    li {
        a {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #fff;
            text-decoration: none;
        }
    }
    .previous {
        a {
            color: #17181B;
        }
        // margin-right: 100px;
        @include media-break-width(320, 767) {
            margin-right: 30px;
        }
    }
    .next {
        a {
            color: #17181B;
        }
    //    margin-left: 100px;
       @include media-break-width(320, 767) {
        margin-left: 30px;
    }
    }
    .page__count {
        margin-right: 15px;
        cursor: pointer;
        &.selected {
            background: #17181B;
            border-radius: 20px;
            height: 40px;
            width: 40px;
            display: grid;
            place-content: center;
            a {
                color: #fff;
            }
            @include media-break-width(320, 767) {
                width: 30px;
                height: 30px;
            }
        }
    }
    
}
.table__height {
    height: 500px;
}