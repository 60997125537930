.base-layout {
  display: grid;
  grid-template-columns: 265px auto;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #0000008c;
  z-index: 100000;
}
.sidebar {
  background-color: #233044;
  color: #fff;
}
.main-header {
  height: 65px;
  width: 100%;
  background-color: #fff;
}
.main-content{
  overflow: hidden;
}
.page-container {
  width: 100%;
  height: calc(100vh - 65px);
  background-color: #edeef4;
  overflow-x: hidden;
  overflow-y: auto;
}
.container {
  width: 100%;
  max-width: calc(100vw - 284px);
  padding: 20px;
  margin: 0 auto;
}
@media (max-width: 1000px) {
  .base-layout {
    grid-template-columns: 1fr;
  }
  .sidebar {
    position: absolute;
    top: 0;
    left: -240px;
    z-index: 100000;
    transition: 0.3s;
  }
  .container {
    width: 100%;
    max-width: 100vw;
    padding: 20px;
    margin: 0 auto;
  }
  .sidebar.true {
    left: 0;
  }
}
